import { handleErrorWithSentry, init, Replay } from "@sentry/sveltekit";
import { PUBLIC_ENVIRONMENT as environment } from '$env/static/public';

init({
  debug: false,
  dsn: 'https://75b3b8252e4d5c34f2181046f31d9024@o4505464480333824.ingest.sentry.io/4506201070043136',
  enabled: environment !== 'local',
  environment: environment,
  tracesSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  integrations: [new Replay()]
});

export const handleError = handleErrorWithSentry();
